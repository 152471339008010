import React, { MouseEventHandler, useEffect, useRef, useState } from "react";
import { Box, Button, ButtonBase, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, SxProps, TextField, Theme, Typography } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import Cards from 'react-credit-cards'
import { colors } from '../../styles';
import { Site, useCheckIfHasTokenMutation, useGetPaymentLinkMutation, useGetSitesQuery, usePayWithTokenMutation, useValidateMfaMutation } from '../../generated/graphql';
import humanize from 'humanize-duration'
import { ClipLoader } from "react-spinners";
import { ExpandMore } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import Countdown from "react-countdown";
import { DateTime } from "luxon";
import 'react-credit-cards/es/styles-compiled.css';
import { Tooltip } from "../../shared/components/Tooltip";
import logo from '../../flag-icon.png'

const shortEnglishHumanizer = humanize.humanizer({
  language: "shortEn",
  languages: {
    shortEn: {
      h: (count) => count === 1 ? "hour" : "hours",
      m: () => "mins",
    }
  }
});

interface IParams {
  qrCode?: string
}

type FeeType = 'hourly' | 'monthly'

interface CardToken {
  id: string;
  cardToken: string;
  cardNumber: string;
  cardType: string;
  cardExpiry: string;
  cardHolder: string;
}

export const Home: React.FC = () => {
  const { qrCode } = useParams<IParams>()
  const [location, setLocation] = useState<string | number>(qrCode ?? '');
  const [checkedCreditCard, setCheckedCreditCard] = useState<boolean>(false);
  const [receiveEmails, setReceiveEmails] = useState<boolean>(false);
  const [receiveSms, setReceiveSms] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [vrm, setVrm] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [duration, setDuration] = useState<number>(0)
  const [timeSteps, setTimeSteps] = useState<number>(0)
  const { data: sites, loading: sitesLoading } = useGetSitesQuery()
  const [error, setError] = useState<string>('')
  const [requestToken, setRequestToken] = useState<boolean>(false)
  const [mfaCode, setMfaCode] = useState<string>('')
  const [step, setStep] = useState<'initial' | 'token' | 'newVrm' | 'newpayment' | 'paytoken'>('initial')
  const [tokens, setTokens] = useState<CardToken[]>([])
  const [plates, setPlates] = useState<string[]>([])
  const [selectedCard, setSelectedCard] = useState<string>('')
  const [selectedVrm, setSelectedVrm] = useState<string>('new')
  const [selectedSite, setSelectedSite] = useState<Site | null>(null)
  const [selectedFee, setSelectedFee] = useState<FeeType>('hourly')
  const [countdown, setCountdown] = useState<Date>()
  const bottomRef = useRef<null | HTMLButtonElement>(null)

  const [getPaymentLink, { loading: paymentLinkLoading }] = useGetPaymentLinkMutation()
  const [checkIfHasToken, { loading: hasTokenLoading }] = useCheckIfHasTokenMutation()
  const [validateMfa, { loading: validateMfaLoading }] = useValidateMfaMutation()
  const [payWithToken, { loading: payWithTokenLoading }] = usePayWithTokenMutation()

  const setSiteInfo = (site: Site) => {
    setSelectedSite(site)
    setDuration(site.minimumTime)
    setTimeSteps(site.timeStep)
  }

  const handleChange = (event: SelectChangeEvent<typeof location>) => {
    setLocation(event.target.value);
    const site = sites?.getSites.find(x => x.qrCode === event.target.value)
    if (site) {
      setSiteInfo(site)
    }
  };

  useEffect(() => {
    if (!selectedSite && location) {
      const site = sites?.getSites.find(x => x.qrCode === location)
      if (site) {
        setSiteInfo(site)
      }
    }
  }, [sites])

  const handleCreditCardChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedCreditCard(event.target.checked);
    if (event.target.checked) {
      setReceiveSms(true)
    }
  };

  const handleReceiveSmsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReceiveSms(event.target.checked);
  };

  const handleReceiveEmailsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReceiveEmails(event.target.checked);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleVrmChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVrm(event.target.value);
  };

  const handlePhoneChange = (newPhone: string) => {
    setPhone(newPhone);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleMfaCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMfaCode(event.target.value);
  };

  const increaseDuration = () => {
    setDuration(duration + timeSteps)
  }

  const decreaseDuration = () => {
    if (duration - timeSteps < 0) {
      return
    }
    setDuration(duration - timeSteps)
  }

  const canBuy = location && duration > 0
    && ((receiveEmails && email) || !receiveEmails)
    && ((receiveSms && phone) || !receiveSms)
    && ((checkedCreditCard && phone) || !checkedCreditCard)
    && ((['initial', 'token'].includes(step) && !vrm) || (step !== 'initial' && vrm))

  const durationText = shortEnglishHumanizer(duration * 60 * 1000, {
    units: ['h', 'm'],
  })
  const [durationFirst, durationSecond] = durationText.split(',')

  const amount = !selectedSite ? 0 :
    selectedFee === 'monthly' ? selectedSite.monthlyFee + selectedSite.transactionFee :
      (Math.round((((duration / 60) * (selectedSite.costPerHour ?? 0)) + selectedSite.transactionFee) * 100) / 100).toFixed(2);

  const processPayment = async () => {
    if (step === 'initial') {
      await processCheckIfHasToken()
    }
    if (step === 'newVrm') {
      await generatePaymentLink()
    }
    if (step === 'token') {
      await processValidateMfa()
    }
    if (step === 'newpayment') {
      await generatePaymentLink()
    }
    if (step === "paytoken") {
      await processPayWithToken()
    }
  }

  const processCheckIfHasToken = async () => {
    if (checkedCreditCard) {
      const result = await checkIfHasToken({
        variables: {
          phone,
        }
      })
      const { data } = result
      if (data) {
        const { checkIfHasToken } = data
        const { hasTokens, error: errorMsg } = checkIfHasToken
        if (errorMsg) {
          setError(errorMsg)
          return
        }
        setError('')
        if (!hasTokens) {
          setStep('newVrm')
          // await generatePaymentLink()
        } else {
          setStep('token')
          setCountdown(DateTime.now().plus({ minutes: 5 }).toJSDate())
          setRequestToken(hasTokens)
          bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
        }
      }
    } else {
      await generatePaymentLink()
    }
  }

  const processValidateMfa = async () => {
    const result = await validateMfa({
      variables: {
        phone,
        token: mfaCode,
      }
    })
    const { data } = result
    if (data) {
      const { validateMfa } = data
      const { successful, tokens: cardsTokens, plates: userPlates, error: errorMsg } = validateMfa
      if (errorMsg) {
        setError(errorMsg)
        return
      }
      if (successful) {
        setError('')
        setStep('paytoken')
        setTokens(cardsTokens)
        setPlates(userPlates)
        setSelectedCard(cardsTokens[0].id)
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  const generatePaymentLink = async () => {
    const result = await getPaymentLink({
      variables: {
        duration,
        name,
        email,
        phone,
        vrm,
        qrCode: selectedSite?.qrCode ?? "",
        storePayment: checkedCreditCard,
        receiveEmails,
        receiveSms,
        isMonthly: selectedFee === 'monthly',
      }
    })
    const { data } = result
    if (data) {
      const { getPaymentLink } = data
      const { error: errorMsg, redirectUrl } = getPaymentLink
      if (errorMsg) {
        setError(errorMsg)
      } else if (redirectUrl) {
        window.open(redirectUrl, '_self', 'noopener,noreferrer')
      }
    } else {
      setError('There was a problem generating the payment link')
    }
  }

  const processPayWithToken = async () => {
    const result = await payWithToken({
      variables: {
        duration,
        name,
        email,
        phone,
        vrm,
        qrCode: selectedSite?.qrCode ?? "",
        storePayment: checkedCreditCard,
        receiveEmails,
        receiveSms,
        token: selectedCard,
        isMonthly: selectedFee === 'monthly',
      }
    })
    const { data } = result
    if (data) {
      const { payWithToken } = data
      const { error: errorMsg, redirectUrl } = payWithToken
      if (errorMsg) {
        setError(errorMsg)
      } else if (redirectUrl) {
        window.open(redirectUrl, '_self', 'noopener,noreferrer')
      }
    } else {
      setError('There was a problem processing the payment. Please try again')
    }
  }

  const choosePaymentMethod = (method: string) => {
    if (method !== 'new') {
      setSelectedCard(method)
      setStep('paytoken')
    } else {
      setSelectedCard('')
      setStep('newpayment')
    }
  }

  const chooseVrm = (plate: string) => {
    if (plate !== 'new') {
      setSelectedVrm(plate)
      setVrm(plate)
    } else {
      setSelectedVrm('new')
      setVrm('')
    }
  }

  const chipsTheme: SxProps<Theme> = {
    height: '100%',
    width: '50%',
    borderColor: colors.orange,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  }

  const isChipSelected = (type: FeeType, selectedType: FeeType) => {
    if (type === selectedType) {
      return {
        ...chipsTheme,
        backgroundColor: colors.orange,
        color: colors.white
      }
    } else {
      return chipsTheme
    }
  }

  const changeSelectedFee = (newFee: FeeType) => {
    if (newFee === 'hourly' && selectedSite) {
      setDuration(selectedSite.minimumTime)
    } else if (newFee === 'monthly') {
      const today = DateTime.now()
      const in4Weeks = today.plus({ weeks: 4 })
      const difference = in4Weeks.diff(today, ['minutes'])
      setDuration(difference.minutes)
    }
    setSelectedFee(newFee)
  }

  const getCards = (tokens: CardToken[]) => {
    return tokens.map((token) => (
      <Cards
        key={token.id}
        expiry={token.cardExpiry}
        name={token.cardHolder}
        number={token.cardNumber}
        cvc={'XXXX'}
        focused={selectedCard === token.id ? 'number' : undefined}
      />
    )).concat([
      <>
        <Stack width={'100%'} alignItems='center' height={100} justifyContent="center">
          <Typography color={colors.orange}>NEW PAYMENT METHOD</Typography>
        </Stack>
      </>
    ])
  }

  const getPlates = (userPlates: string[]) => {
    return userPlates.map((plate) => (
      <Stack direction={'row'} width={'100%'} alignItems='center' height={100} justifyContent="center">
        <Typography color={'black'} fontSize={40} sx={{
        border: '1px solid black',
        width: '60%',
        height: 60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Box width={'20%'} sx={{backgroundColor: colors.plateBlue, height: '100%', textAlign: 'center'}}>
          <img src={logo} alt="Flag logo" width={'70%'} />
        </Box>
        <p style={{width: '80%', textAlign: 'center'}}>{plate}</p>
      </Typography>
      </Stack>
    )).concat([
      <>
        <Stack width={'100%'} alignItems='center' height={100} justifyContent="center">
          <Typography color={colors.orange}>NEW VRM</Typography>
        </Stack>
      </>
    ])
  }

  const loadingButton = validateMfaLoading || paymentLinkLoading || hasTokenLoading || payWithTokenLoading
  console.log(step)

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="location-label">Location</InputLabel>
        <Select
          labelId="location-label"
          value={location}
          label="Location"
          variant='outlined'
          onChange={handleChange}
          disabled={sitesLoading}
          IconComponent={() => <Box paddingRight={1}>{sitesLoading ? <ClipLoader size={'2rem'} color={colors.orange} /> : <ExpandMore />}</Box>}
        >
          <MenuItem value={''}>
            <em>None</em>
          </MenuItem>
          {
            sites && sites.getSites.map((site) => (
              <MenuItem value={site.qrCode} key={site.qrCode}>
                {site.name}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
      {
        location && selectedSite && !sitesLoading && (
          <>
            {
              selectedSite.hasMonthly && (
                <Stack direction={'row'} width='100%' height={35} alignItems='center' spacing={2}>
                  <ButtonBase sx={isChipSelected('hourly', selectedFee)} onClick={() => changeSelectedFee('hourly')}>
                    Hourly Fee
                  </ButtonBase>
                  <ButtonBase sx={isChipSelected('monthly', selectedFee)} onClick={() => changeSelectedFee('monthly')}>
                    Monthly Fee
                  </ButtonBase>
                </Stack>
              )
            }
            {
              selectedFee === 'hourly' &&
              (
                <>
                  <Stack direction={'row'} width={'100%'} justifyContent='space-evenly' alignItems={'center'}>
                    <Button variant='outlined'
                      onClick={decreaseDuration}
                      sx={{ borderRadius: '50%', height: '5rem', width: '5rem', }} disabled={duration <= (selectedSite?.minimumTime ?? 0)}
                    >-</Button>
                    <Stack alignItems={'center'}>
                      <Typography fontWeight={'bold'} fontSize={'2rem'} color={colors.blue}>{durationFirst.trim()}</Typography>
                      {
                        durationSecond && <Typography color={colors.blue}>{durationSecond.trim()}</Typography>
                      }
                    </Stack>
                    <Button variant='outlined'
                      onClick={increaseDuration}
                      sx={{ borderRadius: '50%', height: '5rem', width: '5rem', }}
                    >+</Button>
                  </Stack>
                  <Typography color={colors.blue} textAlign={'center'}>{`Increase or decreate time in intervals of ${selectedSite?.timeStep} minutes with a minimum of ${selectedSite?.minimumTime} minutes`}</Typography>
                </>
              )
            }
            {
              selectedFee === 'monthly' &&
              (
                <Stack alignItems={'center'} height='5rem' justifyContent={'center'}>
                  <Typography fontWeight={'bold'} fontSize={'2rem'} color={colors.blue}>4 Weeks</Typography>
                </Stack>
              )
            }
            <Stack alignItems={'center'}>
              <Typography textAlign={'center'} color={colors.blue}>Credit card fee of ${selectedSite.transactionFee.toFixed(2)} is included in the transaction</Typography>
              <FormGroup>
                <Stack direction={'row'} alignItems='center' justifyContent={'space-between'}>
                  <FormControlLabel control={<Checkbox onChange={handleCreditCardChange} checked={checkedCreditCard} />} label="STORE / USE EXISTING CREDIT CARDS" />
                  <Tooltip text="Using our payment provider, your credit card information will be stored to facilitate the payment process in future purchases." />
                </Stack>
                <Stack direction={'row'} alignItems='center' justifyContent={'space-between'}>
                  <FormControlLabel control={<Checkbox onChange={handleReceiveSmsChange} checked={receiveSms} disabled={checkedCreditCard} />} label="RECEIVE TEXT NOTIFICATIONS" />
                  <Tooltip text="Phone number is used to send notifications and store any information associated to your account. This information is always going to be validated by a 6 digit code sent to your phone number. When clicking on storing or using credit card information, this option must be enabled and can't be unmarked." />
                </Stack>
                {
                  receiveSms && (
                    <MuiTelInput onlyCountries={['NZ', 'CR']}
                      // disableDropdown
                      label="Phone"
                      margin="normal"
                      defaultCountry='NZ' value={phone}
                      inputProps={{
                        maxLength: 14
                      }}
                      fullWidth onChange={handlePhoneChange} />
                  )
                }
                <Stack direction={'row'} alignItems='center' justifyContent={'space-between'}>
                  <FormControlLabel control={<Checkbox onChange={handleReceiveEmailsChange} checked={receiveEmails} />} label="RECEIVE EMAIL NOTIFICATIONS" />
                  <Tooltip text="Email will be stored to send email notifications when a ticket is about to expire or invoice notifications after a payment has been made." />
                </Stack>
                {
                  receiveEmails && (
                    <>
                      <TextField
                        label="Email"
                        value={email}
                        type='email'
                        margin="normal"
                        fullWidth
                        onChange={handleEmailChange}
                      />
                    </>
                  )
                }
              </FormGroup>
            </Stack>
            {
              requestToken && step === 'token' && (
                <>
                  <Typography color={colors.blue}>We would like to validate your information. Please provide the token that has been sent to your phone via SMS before the next 5 minutes</Typography>
                  <Typography color={colors.orange}><Countdown renderer={({ minutes, seconds }) => {
                    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
                  }} date={countdown}></Countdown></Typography>
                  <TextField
                    label="MFA Code"
                    value={mfaCode}
                    fullWidth
                    onChange={handleMfaCodeChange}
                  />
                </>
              )
            }
            {
              tokens.length > 0 && (
                <>
                  <Typography color={colors.blue}>Please select a payment method</Typography>
                  <Carousel
                    sx={{ width: '100%' }}
                    autoPlay={false}
                    swipe={true}
                    fullHeightHover={true}
                    navButtonsAlwaysVisible={true}
                    strictIndexing={false}
                    indicators={false}
                    onChange={(now) => {
                      if (now === undefined) return
                      if (now >= tokens.length) {
                        choosePaymentMethod('new')
                      } else {
                        choosePaymentMethod(tokens[now].id)
                      }
                    }}
                  >
                    {
                      getCards(tokens)
                    }
                  </Carousel>
                </>
              )
            }
            {
              <>
                {
                  plates.length > 0 && (
                    <>
                      <Typography color={colors.blue}>Please select your registration number</Typography>
                      <Carousel
                        sx={{ width: '100%' }}
                        autoPlay={false}
                        swipe={true}
                        height={100}
                        fullHeightHover={true}
                        navButtonsAlwaysVisible={true}
                        strictIndexing={false}
                        indicators={false}
                        onChange={(now) => {
                          if (now === undefined) return
                          if (now >= plates.length) {
                            chooseVrm('new')
                          } else {
                            chooseVrm(plates[now])
                          }
                        }}
                      >
                        {
                          getPlates(plates)
                        }
                      </Carousel>
                    </>
                  )
                }
                {
                  (step === 'newVrm' || (['paytoken', 'newpayment'].includes(step) && selectedVrm === 'new')) && (
                    <TextField
                      label="Vehicle Registration"
                      disabled={selectedVrm !== 'new'}
                      value={vrm}
                      fullWidth
                      onChange={handleVrmChange}
                    />
                  )
                }
              </>
            }
            {
              (!receiveEmails && step === 'paytoken') && (
                <>
                  <TextField
                    label="Email for invoice"
                    value={email}
                    type='email'
                    fullWidth
                    onChange={handleEmailChange}
                  />
                </>
              )
            }
            {
              error && <Typography color={'red'}>{error}</Typography>
            }
            <Button fullWidth disabled={!canBuy}
              ref={bottomRef}
              sx={{ padding: 2 }}
              variant='contained'
              onClick={processPayment}>
              {loadingButton ? <ClipLoader color="white" /> : step === 'token' ? 'VALIDATE' : `PAY $${amount}`}
            </Button>
          </>
        )
      }
    </>
  )
}