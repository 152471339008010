import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type CheckIfHasTokensInput = {
  phone: Scalars['String'];
};

export type MfaTokenInput = {
  phone: Scalars['String'];
  token: Scalars['String'];
};

export type Mutation = {
  checkIfHasToken: Payment;
  getPaymentLink: Payment;
  payWithToken: Payment;
  validateMfa: ValidationResponse;
};


export type MutationCheckIfHasTokenArgs = {
  hasTokensArgs: CheckIfHasTokensInput;
};


export type MutationGetPaymentLinkArgs = {
  paymentArgs: PaymentInput;
};


export type MutationPayWithTokenArgs = {
  paymentArgs: TokenPaymentInput;
};


export type MutationValidateMfaArgs = {
  mfaTokenArgs: MfaTokenInput;
};

export type Payment = {
  error?: Maybe<Scalars['String']>;
  hasTokens: Scalars['Boolean'];
  redirectUrl?: Maybe<Scalars['String']>;
};

export type PaymentInput = {
  /** Number of minutes that the permit is valid for */
  duration: Scalars['Int'];
  /** Email for contact information */
  email?: InputMaybe<Scalars['String']>;
  /** Notifies if user wants to pay a monthly fee */
  isMonthly: Scalars['Boolean'];
  /** User name */
  name: Scalars['String'];
  /** Phone for contact information */
  phone?: InputMaybe<Scalars['String']>;
  /** QR Code Identifier for site */
  qrCode: Scalars['String'];
  /** Flag to receive email notifications */
  receiveEmails: Scalars['Boolean'];
  /** Flag to receive sms notifications */
  receiveSms: Scalars['Boolean'];
  /** Flag to save payment information */
  storePayment: Scalars['Boolean'];
  /** Vehicle registartion number */
  vrm: Scalars['String'];
};

export type PaymentToken = {
  cardExpiry: Scalars['String'];
  cardHolder: Scalars['String'];
  cardNumber: Scalars['String'];
  cardToken: Scalars['String'];
  cardType: Scalars['String'];
  id: Scalars['String'];
};

export type Query = {
  getSites: Array<Site>;
};

export type Site = {
  costPerHour: Scalars['Float'];
  hasMonthly: Scalars['Boolean'];
  id: Scalars['Float'];
  minimumTime: Scalars['Float'];
  monthlyFee: Scalars['Float'];
  name: Scalars['String'];
  paymarkSecret?: Maybe<Scalars['String']>;
  qrCode: Scalars['String'];
  timeStep: Scalars['Float'];
  transactionFee: Scalars['Float'];
  zatParkId: Scalars['Float'];
};

export type TokenPaymentInput = {
  /** Number of minutes that the permit is valid for */
  duration: Scalars['Int'];
  /** Email for contact information */
  email?: InputMaybe<Scalars['String']>;
  /** Notifies if user wants to pay a monthly fee */
  isMonthly: Scalars['Boolean'];
  /** User name */
  name: Scalars['String'];
  /** Phone for contact information */
  phone?: InputMaybe<Scalars['String']>;
  /** QR Code Identifier for site */
  qrCode: Scalars['String'];
  /** Flag to receive email notifications */
  receiveEmails: Scalars['Boolean'];
  /** Flag to receive sms notifications */
  receiveSms: Scalars['Boolean'];
  /** Flag to save payment information */
  storePayment: Scalars['Boolean'];
  /** Token Identifier */
  token: Scalars['String'];
  /** Vehicle registartion number */
  vrm: Scalars['String'];
};

export type ValidationResponse = {
  error?: Maybe<Scalars['String']>;
  plates: Array<Scalars['String']>;
  successful: Scalars['Boolean'];
  tokens: Array<PaymentToken>;
};

export type CheckIfHasTokenMutationVariables = Exact<{
  phone: Scalars['String'];
}>;


export type CheckIfHasTokenMutation = { checkIfHasToken: { error?: string | null, hasTokens: boolean } };

export type GetPaymentLinkMutationVariables = Exact<{
  qrCode: Scalars['String'];
  duration: Scalars['Int'];
  vrm: Scalars['String'];
  name: Scalars['String'];
  storePayment: Scalars['Boolean'];
  receiveEmails: Scalars['Boolean'];
  receiveSms: Scalars['Boolean'];
  email?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  isMonthly: Scalars['Boolean'];
}>;


export type GetPaymentLinkMutation = { getPaymentLink: { redirectUrl?: string | null, error?: string | null } };

export type GetSitesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSitesQuery = { getSites: Array<{ id: number, name: string, zatParkId: number, costPerHour: number, qrCode: string, monthlyFee: number, minimumTime: number, timeStep: number, hasMonthly: boolean, transactionFee: number }> };

export type PayWithTokenMutationVariables = Exact<{
  qrCode: Scalars['String'];
  duration: Scalars['Int'];
  vrm: Scalars['String'];
  name: Scalars['String'];
  storePayment: Scalars['Boolean'];
  receiveEmails: Scalars['Boolean'];
  receiveSms: Scalars['Boolean'];
  email?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
  isMonthly: Scalars['Boolean'];
}>;


export type PayWithTokenMutation = { payWithToken: { redirectUrl?: string | null, error?: string | null } };

export type ValidateMfaMutationVariables = Exact<{
  token: Scalars['String'];
  phone: Scalars['String'];
}>;


export type ValidateMfaMutation = { validateMfa: { successful: boolean, error?: string | null, plates: Array<string>, tokens: Array<{ id: string, cardToken: string, cardNumber: string, cardType: string, cardExpiry: string, cardHolder: string }> } };


export const CheckIfHasTokenDocument = gql`
    mutation CheckIfHasToken($phone: String!) {
  checkIfHasToken(hasTokensArgs: {phone: $phone}) {
    error
    hasTokens
  }
}
    `;
export type CheckIfHasTokenMutationFn = Apollo.MutationFunction<CheckIfHasTokenMutation, CheckIfHasTokenMutationVariables>;

/**
 * __useCheckIfHasTokenMutation__
 *
 * To run a mutation, you first call `useCheckIfHasTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckIfHasTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkIfHasTokenMutation, { data, loading, error }] = useCheckIfHasTokenMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useCheckIfHasTokenMutation(baseOptions?: Apollo.MutationHookOptions<CheckIfHasTokenMutation, CheckIfHasTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckIfHasTokenMutation, CheckIfHasTokenMutationVariables>(CheckIfHasTokenDocument, options);
      }
export type CheckIfHasTokenMutationHookResult = ReturnType<typeof useCheckIfHasTokenMutation>;
export type CheckIfHasTokenMutationResult = Apollo.MutationResult<CheckIfHasTokenMutation>;
export type CheckIfHasTokenMutationOptions = Apollo.BaseMutationOptions<CheckIfHasTokenMutation, CheckIfHasTokenMutationVariables>;
export const GetPaymentLinkDocument = gql`
    mutation GetPaymentLink($qrCode: String!, $duration: Int!, $vrm: String!, $name: String!, $storePayment: Boolean!, $receiveEmails: Boolean!, $receiveSms: Boolean!, $email: String, $phone: String, $isMonthly: Boolean!) {
  getPaymentLink(
    paymentArgs: {qrCode: $qrCode, duration: $duration, vrm: $vrm, name: $name, storePayment: $storePayment, receiveEmails: $receiveEmails, receiveSms: $receiveSms, email: $email, phone: $phone, isMonthly: $isMonthly}
  ) {
    redirectUrl
    error
  }
}
    `;
export type GetPaymentLinkMutationFn = Apollo.MutationFunction<GetPaymentLinkMutation, GetPaymentLinkMutationVariables>;

/**
 * __useGetPaymentLinkMutation__
 *
 * To run a mutation, you first call `useGetPaymentLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getPaymentLinkMutation, { data, loading, error }] = useGetPaymentLinkMutation({
 *   variables: {
 *      qrCode: // value for 'qrCode'
 *      duration: // value for 'duration'
 *      vrm: // value for 'vrm'
 *      name: // value for 'name'
 *      storePayment: // value for 'storePayment'
 *      receiveEmails: // value for 'receiveEmails'
 *      receiveSms: // value for 'receiveSms'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      isMonthly: // value for 'isMonthly'
 *   },
 * });
 */
export function useGetPaymentLinkMutation(baseOptions?: Apollo.MutationHookOptions<GetPaymentLinkMutation, GetPaymentLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetPaymentLinkMutation, GetPaymentLinkMutationVariables>(GetPaymentLinkDocument, options);
      }
export type GetPaymentLinkMutationHookResult = ReturnType<typeof useGetPaymentLinkMutation>;
export type GetPaymentLinkMutationResult = Apollo.MutationResult<GetPaymentLinkMutation>;
export type GetPaymentLinkMutationOptions = Apollo.BaseMutationOptions<GetPaymentLinkMutation, GetPaymentLinkMutationVariables>;
export const GetSitesDocument = gql`
    query GetSites {
  getSites {
    id
    name
    zatParkId
    costPerHour
    qrCode
    monthlyFee
    minimumTime
    timeStep
    hasMonthly
    transactionFee
  }
}
    `;

/**
 * __useGetSitesQuery__
 *
 * To run a query within a React component, call `useGetSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSitesQuery(baseOptions?: Apollo.QueryHookOptions<GetSitesQuery, GetSitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSitesQuery, GetSitesQueryVariables>(GetSitesDocument, options);
      }
export function useGetSitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSitesQuery, GetSitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSitesQuery, GetSitesQueryVariables>(GetSitesDocument, options);
        }
export type GetSitesQueryHookResult = ReturnType<typeof useGetSitesQuery>;
export type GetSitesLazyQueryHookResult = ReturnType<typeof useGetSitesLazyQuery>;
export type GetSitesQueryResult = Apollo.QueryResult<GetSitesQuery, GetSitesQueryVariables>;
export function refetchGetSitesQuery(variables?: GetSitesQueryVariables) {
      return { query: GetSitesDocument, variables: variables }
    }
export const PayWithTokenDocument = gql`
    mutation PayWithToken($qrCode: String!, $duration: Int!, $vrm: String!, $name: String!, $storePayment: Boolean!, $receiveEmails: Boolean!, $receiveSms: Boolean!, $email: String, $phone: String, $token: String!, $isMonthly: Boolean!) {
  payWithToken(
    paymentArgs: {qrCode: $qrCode, duration: $duration, vrm: $vrm, name: $name, storePayment: $storePayment, receiveEmails: $receiveEmails, receiveSms: $receiveSms, email: $email, phone: $phone, token: $token, isMonthly: $isMonthly}
  ) {
    redirectUrl
    error
  }
}
    `;
export type PayWithTokenMutationFn = Apollo.MutationFunction<PayWithTokenMutation, PayWithTokenMutationVariables>;

/**
 * __usePayWithTokenMutation__
 *
 * To run a mutation, you first call `usePayWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payWithTokenMutation, { data, loading, error }] = usePayWithTokenMutation({
 *   variables: {
 *      qrCode: // value for 'qrCode'
 *      duration: // value for 'duration'
 *      vrm: // value for 'vrm'
 *      name: // value for 'name'
 *      storePayment: // value for 'storePayment'
 *      receiveEmails: // value for 'receiveEmails'
 *      receiveSms: // value for 'receiveSms'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      token: // value for 'token'
 *      isMonthly: // value for 'isMonthly'
 *   },
 * });
 */
export function usePayWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<PayWithTokenMutation, PayWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PayWithTokenMutation, PayWithTokenMutationVariables>(PayWithTokenDocument, options);
      }
export type PayWithTokenMutationHookResult = ReturnType<typeof usePayWithTokenMutation>;
export type PayWithTokenMutationResult = Apollo.MutationResult<PayWithTokenMutation>;
export type PayWithTokenMutationOptions = Apollo.BaseMutationOptions<PayWithTokenMutation, PayWithTokenMutationVariables>;
export const ValidateMfaDocument = gql`
    mutation ValidateMfa($token: String!, $phone: String!) {
  validateMfa(mfaTokenArgs: {token: $token, phone: $phone}) {
    tokens {
      id
      cardToken
      cardNumber
      cardType
      cardExpiry
      cardHolder
    }
    successful
    error
    plates
  }
}
    `;
export type ValidateMfaMutationFn = Apollo.MutationFunction<ValidateMfaMutation, ValidateMfaMutationVariables>;

/**
 * __useValidateMfaMutation__
 *
 * To run a mutation, you first call `useValidateMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateMfaMutation, { data, loading, error }] = useValidateMfaMutation({
 *   variables: {
 *      token: // value for 'token'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useValidateMfaMutation(baseOptions?: Apollo.MutationHookOptions<ValidateMfaMutation, ValidateMfaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateMfaMutation, ValidateMfaMutationVariables>(ValidateMfaDocument, options);
      }
export type ValidateMfaMutationHookResult = ReturnType<typeof useValidateMfaMutation>;
export type ValidateMfaMutationResult = Apollo.MutationResult<ValidateMfaMutation>;
export type ValidateMfaMutationOptions = Apollo.BaseMutationOptions<ValidateMfaMutation, ValidateMfaMutationVariables>;